import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getUserBank } from '../../../Store/User/Bank/UserBank';
import axios from 'axios';
import { PAY_URL } from '../../../BaseURL';
import toast from 'react-hot-toast';
import Header from '../Pages/Header';
import Sidebar from '../Pages/Sidebar';
import { getUserDepartment } from '../../../Store/User/Department/UserDepartment';
import { getUserWork } from '../../../Store/User/WorkDay/getUserWork';
import moment from 'moment';
import CopyRight from '../Pages/CopyRight';
import { MultiSelect } from 'react-multi-select-component';

const ManageSalary = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [disable, setDisable] = useState(false);
  const [selectValue, setSelectValue] = useState('');

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const monthTwo = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${monthTwo}-${day}`;
  const [salary, setSalary] = useState({
    employee: "",
    salary_type: "",
    working_day: "",
    working_hour: "",
    perday_salary: 0,
    total_salary: 0,
    basic: 0,
    hra: 0,
    other: 0,
    conveyance_allowance: 0,
    medical_allowance: 0,
    washing: 0,
    bank_name: "",
    bank_branch_name: "",
    bank_account_no: "",
    bank_account_ifsc: "",
    remark: "",
    tds: 0,
    is_pf: false,
    is_esi: false,
    is_pt: false,
  });
  const [salaryTwo, setSalaryTwo] = useState({
    month: currentMonth,
    department: "",
    workDay_id: "",
  });
  const [perhour_ot_salary, setPerhour_ot_salary] = useState(0);
  const [allData, setAllData] = useState({
    designation: "",
    employee_id: "",
    card_no: ""
  });
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [disableMore, setDisableMore] = useState(false);
  const [isSubmitPending, setIsSubmitPending] = useState(false);
  const [allEmpData, setAllEmpData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);

  const data = location.state;
  // console.log(data, '@@');

  useEffect(() => {
    if (localStorage.getItem('PAY_USER_TOKEN') === null) {
      navigate("/user/login");
    }
    getAllEmp()
  }, [navigate]);


  useEffect(() => {
    if (location.state) {
      setSalary({
        salary_type: location?.state?.salary_type,
        working_day: location?.state.working_day,
        working_hour: location?.state?.working_hour,
        perday_salary: location?.state?.perday_salary,
        total_salary: location?.state?.total_salary,
        basic: location.state?.basic,
        hra: location?.state?.hra,
        other: location?.state?.other,
        conveyance_allowance: location?.state?.conveyance_allowance,
        medical_allowance: location?.state?.medical_allowance,
        washing: location?.state?.washing,
        bank_name: location?.state?.bank_name?._id,
        bank_branch_name: location?.state?.bank_branch_name,
        bank_account_no: location?.state?.bank_account_no,
        bank_account_ifsc: location?.state?.bank_account_ifsc,
        is_pf: location?.state?.is_pf,
        is_esi: location?.state?.is_esi,
        is_pt: location?.state?.is_pt,
        remark: location?.state?.remark,
        tds: location?.state?.tds,
        // employee: location.state?.employee?._id
      });
      setSelectedEmployee([{ label: `${location.state.employee.full_name} - ${location.state.employee.employee_id} - ${location.state.employee?.adhar_no}`, value: location.state.employee._id }]);
      setPerhour_ot_salary(location.state?.perhour_ot_salary);
      setSalaryTwo({
        department: location?.state?.department?._id,
        month: location?.state?.month,
        workDay_id: location.state?.work_day_id?._id
      })
      setIsCollapsed(location.state.is_stop_salary);
      setSelectValue(location.state.status);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(getUserBank()),
          dispatch(getUserWork()),
          dispatch(getUserDepartment()),
        ]);
      } catch (error) {
        console.log(error, '!!');
      }
    };

    fetchData();
  }, [dispatch]);

  const bankData = useSelector((state) => state?.getUserBank?.user?.data);
  const workDayData = useSelector((state) => state?.getUserWork?.user?.data);
  const departmentData = useSelector((state) => state?.getUserDepartment?.user?.data);

  const getAllEmp = () => {
    const myurl = `${PAY_URL}/user/get-all-employee`
    axios({
      method: "get",
      url: myurl,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
    }).then(async (response) => {
      if (response?.data?.success) {
        const resData = response?.data?.data
        const filteredData = resData?.filter(e => e.firm_id === localStorage.getItem('PAY_USER_FIRM_ID'));
        setAllEmpData(filteredData);
      }
    }).catch((error) => {
      toast.error("Something went wrong" || error?.response?.data?.message);
    });
  }

  const handleRadioChange = (event) => {
    setSelectValue(event.target.checked);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedSalary = { ...salary, [name]: value };
    const basic = parseFloat(updatedSalary.basic) || 0;
    const hra = parseFloat(updatedSalary.hra) || 0;
    const conveyance_allowance = parseFloat(updatedSalary.conveyance_allowance) || 0;
    const medical_allowance = parseFloat(updatedSalary.medical_allowance) || 0;
    const washing = parseFloat(updatedSalary.washing) || 0;
    const other = parseFloat(updatedSalary.other) || 0;
    updatedSalary.total_salary = basic + hra + conveyance_allowance + medical_allowance + washing + other;

    let perdaySalary = 0;
    if (updatedSalary.salary_type && updatedSalary.total_salary && updatedSalary.working_day) {
      if (updatedSalary.salary_type === 'Monthly') {
        perdaySalary = (
          parseFloat(updatedSalary.total_salary) /
          parseFloat(updatedSalary.working_day)
        )?.toFixed(2)
      } else if (updatedSalary.salary_type === 'Fix') {
        perdaySalary = (
          parseFloat(updatedSalary.total_salary) /
          parseFloat(updatedSalary.working_day)
        )?.toFixed(2)
      } else if (updatedSalary.salary_type === 'Daily') {
        perdaySalary = parseFloat((updatedSalary.total_salary)?.toFixed(2))
      }
    }
    updatedSalary.perday_salary = perdaySalary;
    setSalary(updatedSalary);
  };

  const handleChangeTwo = (e) => {
    setSalaryTwo({ ...salaryTwo, [e.target.name]: e.target.value });
  }

  const selectedEmpChange = (e) => {
    if (e.length > 1) {
      e = e.slice(-1);
    }
    setSelectedEmployee(e);
  }

  useEffect(() => {
    const selectEmployee = allEmpData?.find(emp => emp?._id === selectedEmployee[0]?.value);
    // console.log(selectEmployee, '$#$#$')
    setAllData({
      designation: selectEmployee?.designation?.name,
      card_no: selectEmployee?.card_no || '-',
      employee_id: selectEmployee?.employee_id,
    });
  }, [selectedEmployee, allEmpData]);

  useEffect(() => {
    if (salaryTwo?.department && salaryTwo?.month && workDayData) {
      const newWorkData = workDayData.find(wo => wo.department?._id === salaryTwo.department && wo.month === parseInt(salaryTwo.month));
      if (newWorkData) {
        const workingDays = newWorkData.working_day;
        const workingHour = newWorkData.ot_hours;
        setSalary(prevSalary => ({ ...prevSalary, working_day: workingDays, working_hour: workingHour }));
        setSalaryTwo(prevSalaryTwo => ({ ...prevSalaryTwo, workDay_id: newWorkData._id }));
      }
    }
  }, [salaryTwo.department, salaryTwo.month, workDayData]);

  const handleCheck = (e) => {
    const { id, checked } = e.target;

    setSalary((prevSalary) => ({
      ...prevSalary,
      [id]: checked,
    }));
  };

  const handleStopChange = (e) => {
    setIsCollapsed(!isCollapsed);
  }

  // const handleSubmit = (more) => {
  //   if (!data?._id) {
  //     if (formattedDate > moment(localStorage.getItem('PAY_USER_END_YEAR')).format('YYYY-MM-DD')) {
  //       toast.error('Current date has passed the financial year end date. Please contact the administrator to add the new financial year.');
  //       return;
  //     }
  //   }
  //   if (validation()) {
  //     if (parseFloat(perhour_ot_salary) === 0 || perhour_ot_salary === '') {
  //       const countPerHour = parseFloat(salary.perday_salary) / parseFloat(salary.working_hour);
  //       setPerhour_ot_salary(parseFloat(countPerHour.toFixed(2)));
  //       setIsSubmitPending(true);
  //     } else {
  //       performSubmit(more);
  //     }
  //   };
  // }

  // useEffect(() => {
  //   if (isSubmitPending && perhour_ot_salary !== 0) {
  //     performSubmit();
  //     setIsSubmitPending(false);
  //   }
  //   // eslint-disable-next-line
  // }, [perhour_ot_salary, isSubmitPending]);

  const handleSubmit = (more) => {
    if (!data?._id) {
      if (formattedDate > moment(localStorage.getItem('PAY_USER_END_YEAR')).format('YYYY-MM-DD')) {
        toast.error('Current date has passed the financial year end date. Please contact the administrator to add the new financial year.')
        return
      }
    }

    const countPerHour = parseFloat(salary.perday_salary) / parseFloat(salary.working_hour);
    setPerhour_ot_salary(parseFloat(countPerHour.toFixed(2)));

    setDisable(true);
    if (more === 'true') {
      setDisableMore(true);
    }
    if (validation()) {
      const bodyFormData = new URLSearchParams();
      bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
      bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));
      bodyFormData.append('employee', selectedEmployee[0]?.value);
      bodyFormData.append('salary_type', salary?.salary_type);
      bodyFormData.append('working_day', salary?.working_day);
      bodyFormData.append('working_hour', salary?.working_hour);
      bodyFormData.append('perday_salary', salary?.perday_salary);
      bodyFormData.append('total_salary', salary?.total_salary);
      bodyFormData.append('basic', salary?.basic);
      bodyFormData.append('hra', salary?.hra);
      bodyFormData.append('conveyance_allowance', salary?.conveyance_allowance);
      bodyFormData.append('medical_allowance', salary?.medical_allowance);
      bodyFormData.append('washing', salary?.washing);
      bodyFormData.append('other', salary?.other);
      bodyFormData.append('month', salaryTwo?.month);
      bodyFormData.append('department', salaryTwo?.department);
      bodyFormData.append('work_day_id', salaryTwo?.workDay_id);
      bodyFormData.append('bank_name', salary?.bank_name);
      bodyFormData.append('bank_branch_name', salary?.bank_branch_name);
      bodyFormData.append('bank_account_no', salary?.bank_account_no);
      bodyFormData.append('bank_account_ifsc', salary?.bank_account_ifsc);
      bodyFormData.append('is_pf', salary?.is_pf);
      bodyFormData.append('is_esi', salary?.is_esi);
      bodyFormData.append('is_pt', salary?.is_pt);
      bodyFormData.append('is_stop_salary', isCollapsed);
      bodyFormData.append('remark', salary?.remark);
      bodyFormData.append('tds', salary?.tds === null ? 0 : salary?.tds);
      bodyFormData.append('perhour_ot_salary', parseFloat(countPerHour.toFixed(2)))
      if (data?._id) {
        bodyFormData.append('id', data?._id);
        bodyFormData?.append('status', selectValue);
      }
      axios({
        method: 'post',
        url: `${PAY_URL}/user/manage-salary`,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
      }).then((response) => {
        // console.log(response?.data);
        if (response.data.success === true) {

          if (more !== 'true') {
            navigate('/user/salary-management');
          }
          toast.success(response?.data?.message)
          handleReset();
          setIsCollapsed(false);
        }
        setDisable(false);
        if (more === 'true') {
          setDisableMore(false);
        }
      }).catch((error) => {
        // console.log(error);
        toast.error(error?.response?.data?.message);
        setDisable(false);
        if (more === 'true') {
          setDisableMore(false);
        }
      });
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  }

  const validation = () => {
    let isValid = true;
    let err = {};

    if (!selectedEmployee?.length > 0) {
      isValid = false;
      err['employee_err'] = 'Please select employee'
    }
    if (!salaryTwo?.department) {
      isValid = false;
      err['department_err'] = 'Please select department'
    }
    if (!salaryTwo?.month) {
      isValid = false;
      err['month_err'] = 'Please select month'
    }
    if (!salaryTwo?.workDay_id) {
      isValid = false;
      toast.error('Please enter working day data for this month and department')
    }
    if (!salary?.salary_type) {
      isValid = false;
      err['salary_type_err'] = 'Please select salary type'
    }
    if (!salary?.working_hour) {
      isValid = false;
      err['working_hour_err'] = 'Please enter working hours'
    }
    if (!salary?.working_day) {
      isValid = false;
      err['working_day_err'] = 'Please enter working day'
    }
    // if (!salary?.basic) {
    //   isValid = false;
    //   err['basic_err'] = 'Please enter basic salary'
    // }

    if (salary.total_salary === 0) {
      isValid = false;
      err['total_salary_err'] = 'Total salary cannot be zero. Please add at least one salary data.';
    }
    if (!salary?.bank_name) {
      isValid = false;
      err['bank_name_err'] = 'Please select bank name'
    }
    if (!salary?.bank_branch_name) {
      isValid = false;
      err['bank_branch_name_err'] = 'Please enter branch name'
    }
    if (!salary?.bank_account_ifsc) {
      isValid = false;
      err['bank_account_ifsc_err'] = 'Please enter account IFSC';
    } else {
      const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/i;
      if (!ifscPattern.test(salary.bank_account_ifsc)) {
        isValid = false;
        err['bank_account_ifsc_err'] = 'Please enter a valid IFSC code';
      }
    }

    if (!salary?.bank_account_no) {
      isValid = false;
      err['bank_account_no_err'] = 'Please enter account no.'
    }
    if (isCollapsed === true) {
      if (!salary?.remark) {
        isValid = false;
        err['remark_err'] = 'Please enter remark'
      }
    } else if (isCollapsed === false) {
      if (salary?.remark) {
        isValid = false;
        toast.error('Please remove remark before unchecking stop salary')
      }
    }
    const nonNegativeFields = [
      'tds', 'perday_salary', 'total_salary', 'basic',
      'hra', 'other', 'conveyance_allowance', 'medical_allowance', 'washing'
    ];
    nonNegativeFields.forEach(field => {
      if (salary[field] < 0) {
        isValid = false;
        err[`${field}_err`] = `${field.replace(/_/g, ' ')} cannot be negative`;
      }
    });
    if (perhour_ot_salary < 0) {
      isValid = false;
      err['perhour_ot_salary_err'] = 'Perhour ot salary cannot be negative'
    }

    setError(err);
    return isValid;
  }

  const handleReset = () => {
    setSalary({
      employee: "",

      salary_type: "",
      working_day: "",
      working_hour: "",
      perday_salary: 0,
      total_salary: 0,
      basic: 0,
      hra: 0,
      other: 0,
      conveyance_allowance: 0,
      medical_allowance: 0,
      washing: 0,
      bank_name: "",
      bank_branch_name: "",
      bank_account_no: "",
      bank_account_ifsc: "",

      remark: "",
      tds: 0,

      is_pf: false,
      is_esi: false,
      is_pt: false,
    });
    setSalaryTwo({
      month: currentMonth,
      department: "",
      workDay_id: "",
    })
    setIsCollapsed(false);
    setPerhour_ot_salary(0);
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleOpen = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
      <Header handleOpen={handleOpen} />
      <Sidebar />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title user-title">{data?._id ? 'Edit' : 'Add'} Salary</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item"><Link to='/user/salary-management'>Salary Management</Link></li>
                  <li className="breadcrumb-item active">{data?._id ? 'Edit' : 'Add'} Salary</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="card p-3 basicform">
            <div className=" mb-3 titleForm border-bottom">
              <h4 className="">Salary</h4>
            </div>

            <div className="row card_id">

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="month" className="form-label">Month <span className='text-danger'> *</span></label>
                    <select className="form-select form-control" aria-label="Default select example"
                      onChange={handleChangeTwo} name='month' value={salaryTwo?.month} disabled={data?._id ? 'disabled' : ''}
                    >
                      <option value=''>Select month</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                    <div className='error'>{error?.month_err}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row card_id'>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="salaryDepartment" className="form-label">Department <span className='text-danger'> *</span></label>
                    <select className="form-select form-control" aria-label="Default select example"
                      onChange={handleChangeTwo} value={salaryTwo?.department} name='department'
                    >
                      <option selected>Select Department</option>
                      {departmentData?.map((e) =>
                        <option value={e?._id} key={e?._id}>{e?.name}</option>
                      )}
                    </select>
                    <div className='error'>{error?.department_err}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-lg-8 col-xxl-8 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="workDepartment" className="form-label">Employee - Id - Card Id - Aadhar card  <span className='text-danger'> *</span></label>
                    {/* <MultiSelect
                      options={allEmpData?.map(e => ({ label: `${e.full_name} - ${e?.employee_id} - ${e?.adhar_no}`, value: e._id }))}
                      onChange={selectedEmpChange}
                      hasSelectAll={false}
                      value={selectedEmployee}
                      labelledBy="Select Employee"
                    /> */}
                    <MultiSelect
                      options={allEmpData?.map(e => ({
                        label: `${e.full_name} - ${e.employee_id} - ${e.card_no ? e.card_no : '-'} - ${e.adhar_no}`,
                        value: e._id,
                        searchableLabel: `${e.full_name} ${e.employee_id} ${e.card_no || ''}`
                      }))}
                      onChange={selectedEmpChange}
                      hasSelectAll={false}
                      value={selectedEmployee}
                      labelledBy="Select Employee"
                      filterOptions={(options, filter) =>
                        options.filter(({ searchableLabel }) =>
                          searchableLabel.toLowerCase().includes(filter.toLowerCase())
                        )
                      }
                    />
                    <div className='error'>{error?.employee_err}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row card_id'>
              {selectedEmployee?.length > 0 ? (
                <>
                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label className="form-label">Id</label>
                        <input className="form-control" value={allData?.employee_id} disabled />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label className="form-label">Gate Pass No.</label>
                        <input className="form-control" value={allData?.card_no} disabled />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label className="form-label">Designation</label>
                        <input className="form-control" value={allData?.designation} disabled />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            <div className="row card_id">
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="salaryType" className="form-label">Salary Type <span className='text-danger'> *</span></label>
                    <select className="form-select form-control" aria-label="Default select example"
                      onChange={handleChange} name='salary_type' value={salary?.salary_type}
                    >
                      <option value=''>Select Salary Type</option>
                      <option value="Daily">Daily</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Fix">Fix</option>
                    </select>
                    <div className='error'>{error?.salary_type_err}</div>
                  </div>
                </div>
              </div>

              {salaryTwo?.department &&
                <>
                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label htmlFor="workingDays" className="form-label">Working Days <span className='text-danger'> *</span></label>
                        <input type="number" className="form-control" id="workingDays"
                          onChange={handleChange} name='working_day' value={salary?.working_day}
                          onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown} disabled
                        />
                        <div className='error'>{error?.working_day_err}</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label htmlFor="workingHour" className="form-label">Working Hour <span className='text-danger'> *</span></label>
                        <input type="number" className="form-control" id="workingHour"
                          name='working_hour' onChange={handleChange} value={salary?.working_hour}
                          onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown} disabled
                        />
                        <div className='error'>{error?.working_hour_err}</div>
                      </div>
                    </div>
                  </div>
                </>

              }
            </div>

            <div className="row card_id">
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="basicSalary" className="form-label">Basic Salary</label>
                    <input type="number" className="form-control" id="basicSalary"
                      value={salary?.basic} name='basic' onChange={handleChange}
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                    />
                    <div className='error'>{error?.basic_err}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row card_id'>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="hra" className="form-label">Hra</label>
                    <input type="number" className="form-control" id="hra"
                      onChange={handleChange} name='hra' value={salary?.hra}
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                    />
                    <div className='error'>{error?.hra_err}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row card_id'>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="conveyance" className="form-label">Conveyance Allowance</label>
                    <input type="number" className="form-control" id="conveyance"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      onChange={handleChange} name='conveyance_allowance' value={salary?.conveyance_allowance}
                    />
                    <div className='error'>{error?.conveyance_allowance_err}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row card_id">
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="medical" className="form-label">Medical Allowance</label>
                    <input type="number" className="form-control" id="medical"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      onChange={handleChange} value={salary?.medical_allowance} name='medical_allowance'
                    />
                    <div className='error'>{error?.medical_allowance_err}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row card_id'>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="washing" className="form-label">Washing</label>
                    <input type="number" className="form-control" id="washing"
                      onChange={handleChange} name='washing' value={salary?.washing}
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                    />
                    <div className='error'>{error?.washing_err}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row card_id'>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="other" className="form-label">Other</label>
                    <input type="number" className="form-control" id="other"
                      onChange={handleChange} name='other' value={salary?.other}
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown} />
                    <div className='error'>{error?.other_err}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row card_id'>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="totalSalary" className="form-label">Total Salary</label>
                    <input type="number" className="form-control" id="totalSalary"
                      onChange={handleChange} name='total_salary' value={salary?.total_salary} disabled
                    />
                    <div className='error'>{error?.total_salary_err}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="perdaySalary" className="form-label">Perday Salary</label>
                    <input type="number" className="form-control" id="perdaySalary"
                      name='perday_salary' onChange={handleChange} value={salary?.perday_salary} disabled />
                  </div>
                </div>
              </div>

              {/* --PERHOUR OT SALARY, WORKING DAY, WORKING HOURS ARE AUTO UPDATE FROM WORKINGDAYS MASTER THAT'S WHY DISABLED */}
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="perHourSalary" className="form-label">Perhour OT Salary</label>
                    <input type="number" className="form-control" id="perHourSalary"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      name='perhour_ot_salary' onChange={(e) => setPerhour_ot_salary(e.target.value)} value={perhour_ot_salary} disabled />
                    <div className='error'>{error.perhour_ot_salary_err}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card p-3 basicform">
            <div className=" mb-3 titleForm border-bottom">
              <h4 className="">Taxes</h4>
            </div>
            <div className='row card_id mb-3'>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label className="form-label">TDS</label>
                    <input type="number" className="form-control"
                      name='tds' onChange={handleChange} value={salary?.tds} />
                  </div>
                </div>
              </div>
            </div>

            <div className='row card_id'>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={salary.is_pf}
                      onChange={handleCheck}
                      id="is_pf"
                    />
                    <label className="form-check-label" htmlFor="is_pf">
                      PF
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={salary.is_esi}
                      onChange={handleCheck}
                      id="is_esi"
                    />
                    <label className="form-check-label" htmlFor="is_esi">
                      ESI
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={salary.is_pt}
                      onChange={handleCheck}
                      id="is_pt"
                    />
                    <label className="form-check-label" htmlFor="is_pt">
                      PT
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card p-3 Blacklistform ">
            <div className=" mb-3 titleForm border-bottom">
              <h4 className="">Stop Salary</h4>
            </div>
            <div className=" card m-0 checkCard">
              <div className="card_id ">
                <div className="d-flex align-items-center gap-2 bg-body-secondary ">
                  <h4 className="p-2 m-0" style={{ borderRadius: "3px" }}>Stop Salary</h4>
                  <div className="d-flex gap-2 align-center">
                    <input type="checkbox" className="d-flex form-check-input m-0"
                      checked={isCollapsed}
                      id="addressCheckbox"
                      onChange={handleStopChange}
                    />
                    <label htmlFor="">Are you sure you want to stop the salary?</label>
                  </div>
                </div>
                <div className={`collapse mt-3 ${!isCollapsed ? ' ' : 'show'}`} id="collapseExample">
                  <div className=" card-body">
                    <div className="row card_id">
                      <div className="col-md-12 col-lg-12 col-xxl-12 col-sm-12">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label htmlFor="remark" className="form-label">Remark</label>
                            <textarea className="form-control" placeholder="Comment here...." onChange={handleChange}
                              name='remark' value={salary?.remark}
                              id="remark"></textarea>
                            <div className='error'>{error?.remark_err}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="card p-3 basicform">
            <div className=" mb-3 titleForm border-bottom">
              <h4 className="">Bank</h4>
            </div>
            <div className="row card_id">
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="bankName" className="form-label">Bank Name <span className='text-danger'> *</span></label>
                    <select className="form-select form-control" aria-label="Default select example"
                      onChange={handleChange} name='bank_name' value={salary?.bank_name}
                    >
                      <option value=''>Select Bank</option>
                      {bankData?.map((e) =>
                        <option value={e?._id} key={e?._id}>{e?.name}</option>
                      )}
                    </select>
                    <div className='error'>{error?.bank_name_err}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="bankBranchName" className="form-label">Bank Branch Name <span className='text-danger'> *</span></label>
                    <input type="text" className="form-control" id="bankBranchName"
                      onChange={handleChange} name='bank_branch_name' value={salary?.bank_branch_name}
                    />
                    <div className='error'>{error?.bank_branch_name_err}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="bankNumber" className="form-label">Bank Account Number <span className='text-danger'> *</span></label>
                    <input type="number" className="form-control" id="bankNumber"
                      onChange={handleChange} name='bank_account_no' value={salary?.bank_account_no}
                    />
                    <div className='error'>{error?.bank_account_no_err}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row card_id">
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="bankIrfc" className="form-label">Bank Account IFSC <span className='text-danger'> *</span></label>
                    <input type="text" className="form-control" id="bankIrfc"
                      onChange={handleChange} name='bank_account_ifsc' value={salary?.bank_account_ifsc}
                    />
                    <div className='error'>{error?.bank_account_ifsc_err}</div>
                  </div>
                </div>
              </div>

              {data?._id ? (
                <div className='col-md-4 col-lg-4 col-xxl-4 col-sm-12'>
                  <div className="cardNum">
                    <div className="mb-3">
                      <label htmlFor="fileUpload" className="form-label">Status</label>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" onChange={handleRadioChange} checked={selectValue} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="row card_id mt-4 sm-mt-3">
              <div className=" col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3 d-flex gap-2">
                    <button className="btn btn-success w-50" type='button' onClick={handleSubmit} disabled={disable}>{disable ? "Processing..." : (data?._id ? 'Update' : 'Submit')}</button>
                    <button className="btn btn-outline-secondary w-50" type='button' onClick={handleReset}>Reset</button>
                    {!data?._id ? <button type="button" className="btn btn-outline-success w-50" onClick={() => handleSubmit('true')} disabled={disableMore}>{disableMore ? "Processing..." : 'Add More'}</button> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CopyRight />
      </div>
    </div >
  )
}

export default ManageSalary